<template>
  <div class="schema-property-editor">
    <b-tabs :animated="false" type="is-boxed">
      <b-tab-item icon="table">
        <div class="forms">
          <b-collapse v-if="hasSpecificOptions" class="card">
            <div
              slot="trigger"
              slot-scope="props"
              class="card-header"
              role="button">
              <a class="card-header-icon">
                <i :class="props.open ? 'mdi mdi-menu-down' : 'mdi mdi-menu-right'" />
              </a>
              <p class="card-header-title">
                {{ i18n.field_settings }}
              </p>
            </div>
            <vue-form-generator
              class="form property-form"
              :schema="specificOptions"
              :model="value"
              tag="div"
            />
          </b-collapse>
          <b-collapse class="card" :open="!hasSpecificOptions">
            <div
              slot="trigger"
              slot-scope="props"
              class="card-header"
              role="button">
              <a class="card-header-icon">
                <i :class="props.open ? 'mdi mdi-menu-down' : 'mdi mdi-menu-right'" />
              </a>
              <p class="card-header-title">
                {{ i18n.common_settings }}
              </p>
            </div>
            <vue-form-generator
              class="form property-form"
              :schema="fieldSchema"
              :model="value"
              tag="div"
            />
          </b-collapse>
        </div>
      </b-tab-item>
      <b-tab-item icon="code-braces">
        <codemirror
          v-model="propertyAsString"
          :options="editorOptions"
          class="raw-json-editor"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>
<script>
import JSON5 from 'json5';
import 'codemirror/theme/idea.css';

import codemirror from '@/components/ui/Codemirror';
import vfgFields from '@/core/vfgfields';
import i18n from 'i18n/components/SchemaPropertyEditor.json';
import defaultFieldSchema from '@/core/defaultFieldSchema';

export default {
  name: 'SchemaPropertyEditor',
  components: {
    codemirror,
  },
  props: {
    value: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    let propertyAsString;
    try {
      propertyAsString = JSON5.stringify(this.value, null, 2);
    } catch (e) {}

    if (propertyAsString === undefined) {
      propertyAsString = '{}';
    }

    return {
      i18n,
      propertyAsString,
      valueCopy: JSON5.parse(propertyAsString),
      fieldSchema: defaultFieldSchema,
      specificOptions: {
        fields: vfgFields.specificOptions,
      },
      editorOptions: {
        tabSize: 4,
        mode: { name: 'javascript', json: true },
        theme: 'idea',
        lineNumbers: true,
        lineWrapping: true,
        line: true,
        autofocus: true,
        keyMap: 'sublime',
        autoCloseBrackets: true,
        extraKeys: {
          'Ctrl-Enter': () => {
            this.save();
          },
        },
      },
    };
  },
  computed: {
    hasSpecificOptions() {
      return this.specificOptions && this.specificOptions.fields && this.specificOptions.fields.length;
    },
  },
  watch: {
    value(oldValue, newValue) {
      if (oldValue.label !== newValue.label && oldValue.model !== newValue.model) {
        this.propertyAsString = JSON5.stringify(this.value, null, 2);
        this.valueCopy = JSON5.parse(this.propertyAsString);
      }
    },
    propertyAsString: {
      handler() {
        try {
          this.$emit('input', JSON5.parse(this.propertyAsString));
        } catch (e) {
          console.error(e);
        }
      },
    },
    valueCopy: {
      handler(v) {
        this.propertyAsString = JSON.stringify(v);
      },
      deep: true,
    },
  },
  methods: {
    save() {
    },
  },
};
</script>
<style scoped>
h3 {
  font-size: 130%;
  font-weight: bold;
  margin-bottom: 20px;
}
.schema-property-editor >>> .tab-content {
  padding: 0;
}
.schema-property-editor >>> .CodeMirror {
  height: 300px !important;
}
.forms {
  overflow-y: auto;
  height: calc(500px - 43px);
}
.form {
  padding: 10px;
}
.schema-property-editor >>> .hint {
  grid-column: 2;
}
</style>
<style>
.property-form .field-type .dropdown-menu {
  width: calc(200% + 200px);
}
</style>
