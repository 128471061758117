import { isMobile } from 'mobile-device-detect';
import mapObject from 'map-obj';
import { mapState } from 'vuex';
import PermissionManager from '@/permissions/PermissionManager';
import actions from '@/permissions/actions';
import deepClone from '@/core/utils/deepClone';
import i18n from 'i18n/validators.json';
import vfgFields from '../vfgfields';

export default {
  computed: {
    ...mapState({
      userDetails: (state) => state.login.userDetails,
    }),
  },
  methods: {
    prepareField(field) {
      if (field.type === undefined || vfgFields.fields.filter((f) => f.originalLabel === field.type).length === 0) {
        // field.type = 'placeholder';
        // field.text = 'Field not available';
      }
      if (field._id === undefined) {
        field._id = `f_id_${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`;
      }

      field.attributes = {
        'data-field-for': field._id,
      };

      field.buttons = [{
        classes: 'mdi mdi-wrench',
        label: '',
        onclick: (model) => {
          if (this.openPropertyEditorForField) {
            this.openPropertyEditorForField(field);
          } else {
            console.warn('cannot call openPropertyEditorForField, because it does not exists in current component', this);
          }
        },
      }];
      if (field.type === 'array') {
        field.buttons.push({
          classes: 'mdi mdi-playlist-edit',
          label: '',
          onclick: (model) => {
            if (this.openPropertyEditorForField) {
              console.log('field', field);
              if (!field.items) {
                field.items = {};
              }
              if (!field.items._id) {
                field.items._id = `f_id_${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`;
              }
              this.openPropertyEditorForField(field.items);
            } else {
              console.warn('cannot call openPropertyEditorForField, because it does not exists in current component', this);
            }
          },
        });
      }
      if (field.type === 'object' && field.schema && field.schema.fields) {
        for (const objectField of field.schema.fields) {
          this.prepareField(objectField);
        }
      }
    },
    prepareSchema(schema) {
      let hasAdvancedFields = false;
      const newSchema = deepClone(schema);
      if (newSchema.fields) {
        mapObject(newSchema, (key, value, source) => {
          if (key === 'advanced' && value === true) {
            hasAdvancedFields = true;
            source.visible = () => this.advanced;
          }
          if (key === 'validatorFunction' && value) {
            source.validator = this.getValidatorFunction(value);
          }
          if (key === 'getterFunction') {
            source.get = new Function('model', value);
          }
          if (key === 'setterFunction') {
            source.set = new Function('model', value);
          }
          if (key === 'visibilityFunction') {
            source.visible = new Function('model', value);
          }
          return [key, value];
        }, { deep: true });

        if (!isMobile && PermissionManager.can(actions.can_edit_schemas)) {
          for (const field of newSchema.fields) {
            this.prepareField(field);
          }
        }
      }
      this.hasAdvancedFields = hasAdvancedFields;
      return newSchema;
    },
    getValidatorFunction(validatorRule) {
      if (validatorRule.type === 'regex') {
        return (value) => {
          const re = new RegExp(validatorRule.regex);

          if (!re.test(value)) {
            return [i18n.invalid_value];
          }
          return [];
        };
      }
      return validatorRule.type;
    },
  },
};
