<template>
  <fieldset class="form">
    <div class="title">
      Utilisateurs pouvant voir le document {{ group }} {{ collection }}
    </div>
    <div class="columns">
      <div class="column">
        <div class="subtitle">
          Via les droits de la collection
        </div>
        <div v-if="collectionRightUsers && collectionRightUsers.length">
          <div v-for="u in collectionRightUsers" :key="u.username" class="card" style="padding: 10px;">
            <i class="mdi mdi-account" />
            {{ u.username }}
          </div>
        </div>
        <div v-else class="content has-text-grey has-text-centered">
          <p>
            <i class="mdi mdi-emoticon-sad mdi-48px" />
          </p>
          <p>Nothing here.</p>
        </div>
      </div>
      <div class="column">
        <div class="subtitle">
          Via les droits sur le groupe
        </div>
        <div v-if="groupRightUsers && groupRightUsers.length">
          <div v-for="u in groupRightUsers" :key="u.username" class="card" style="padding: 10px;">
            <i class="mdi mdi-account" />
            {{ u.username }}
          </div>
        </div>
        <div v-else class="content has-text-grey has-text-centered">
          <p>
            <i class="mdi mdi-emoticon-sad mdi-48px" />
          </p>
          <p>Nothing here.</p>
        </div>
      </div>
    </div>
  </fieldset>
</template>
<script>
import Api from '@/core/Api';

export default {
  props: {
    collection: {
      type: String,
      required: true,
    },
    group: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      users: undefined,
      collectionRightUsers: undefined,
      groupRightUsers: undefined,
    };
  },
  watch: {
    collection: {
      handler: 'fetchUsers',
      immediate: true,
    },
    group: {
      handler: 'fetchUsers',
      immediate: true,
    },
  },
  methods: {
    async fetchUsers() {
      const clauses = [{
        roles: `collection:${this.collection}`,
      }, {
        roles: `collection:${this.collection}:read`,
      }];
      if (this.group && this.group !== '') {
        clauses.push({
          roles: `group:${this.group}`,
        });
      }
      const res = await Api.get('/users', {
        params: {
          query: { $or: clauses },
        },
      });
      this.users = res.data.documents;
      this.collectionRightUsers = [];
      this.groupRightUsers = [];
      for (const u of this.users) {
        if (this.hasCollectionRight(u)) {
          this.collectionRightUsers.push(u);
        }
        if (this.hasGroupRight(u)) {
          this.groupRightUsers.push(u);
        }
      }
    },
    hasCollectionRight(user) {
      if (user.roles && (user.roles.includes(`collection:${this.collection}`) || user.roles.includes(`collection:${this.collection}:read`))) {
        return true;
      }
      return false;
    },
    hasGroupRight(user) {
      if (user.roles && (user.roles.includes(`group:${this.group}`) || user.roles.includes(`group:${this.group}:read`))) {
        return true;
      }
      return false;
    },
  },
};
</script>
