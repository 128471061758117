<template>
  <div class="custom-tab-editor no-print">
    <b-collapse class="card" animation="slide" v-model="editing" position="is-bottom">
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          aria-controls="contentIdForA11y3">
          <a class="card-header-icon">
            <i :class="`mdi mdi-chevron-double-${props.open ? 'up': 'down'}`" />
          </a>
          <p class="card-header-title">
            Editer l'onglet
          </p>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <div v-show="isPortalActive" :style="isPortalActive ? 'max-height: 600px; overflow-y: auto;': 'height: 0px'">
            <portal-target name="portalComplexField" @change="isPortalActive = $event" :style="isPortalActive ? 'height:100%': ''"/>
          </div>
          <vue-form-generator
            v-show="!isPortalActive"
            ref="form"
            :schema="customTabEditorSchema"
            :model="tab"
            class="form main-form"
            tag="div"
          />
          <div class="buttons">
            <a class="is-danger" @click="$emit('delete')">
              <i class="mdi mdi-delete" />
              Supprimer l'onglet
            </a>
            <a class="button is-primary" @click="$emit('save')">
              Sauver
            </a>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import customTabEditorSchema from '@/core/customTabEditorSchema';

export default {
  props: {
    tab: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      editing: false,
      isPortalActive: false,
      customTabEditorSchema
    }
  },
  watch: {
    editing (v) {
      if (v) {
        this.$emit('enterEditMode');
      } else {
        this.$emit('leaveEditMode');
      }
    }
  },
};
</script>
<style scoped>
.custom-tab-editor {
  transition: height 0.2s;
}
.card {
  box-shadow: none;
  margin-bottom: 10px;
}
.card-header {
  background: whitesmoke;
  display: flex;
  border-radius: 0 0 6px 6px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  max-width: 600px;
  margin: auto;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
}
.card-header a {
  display: block;
}
.card-header-title {
}
.header .edit-button {
  flex-grow: 1;
}
.buttons {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

a.is-danger {
  color: var(--red-color);
}
</style>
