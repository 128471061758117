<template>
  <div class="field">
    <input v-model="value" class="input" type="text" @input="$emit('input', $event)">
    <p class="control">
      <button class="button" @click="visibilityPreview = !visibilityPreview">
        <i class="mdi mdi-eye" />
      </button>
    </p>
    <div v-if="visibilityPreview">
      <portal to="portalComplexField">
        <div class="complex-field-editor group-field">
          <a class="back-button" @click="visibilityPreview = false">
            <i class="mdi mdi-arrow-left" />
            {{ i18n.back }}
          </a>
          <VisibilityPreview :collection="collection" :group="value" />
        </div>
      </portal>
    </div>
  </div>
</template>
<script>
import VisibilityPreview from '@/components/VisibilityPreview';
import i18n from 'i18n/components/ui/InputGroup.json';

export default {
  name: 'InputGroup',
  components: {
    VisibilityPreview,
  },
  props: {
    collection: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      i18n,
      visibilityPreview: false,
    };
  },
};
</script>
