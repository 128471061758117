<template>
  <div style="padding: 20px;">
    <h2 class="title is-2">Commentaires</h2>
    <b-loading :is-full-page="false" :active="isLoading" :can-cancel="false" />
    <div v-for="c in comments" :key="c._id" class="message-bubble">
      <span class="author">
        <img :src="`https://avatars.dicebear.com/api/initials/${c.author}.svg`" :alt="c.author" />
        &nbsp;
        <span class="name">{{c.author}}</span>
      </span>
      <span class="date">
        {{new Date(c.date).toLocaleString()}}
      </span>
      <div>
        {{c.message}}
      </div>
    </div>
    <div v-if="!isLoading && comments.length === 0" style="padding: 60px; font-size: 140%;">
      Pas encore de commentaire ici ...
    </div>
    <div class="new-message-content no-print">
      <div>
        <textarea v-model="newCommentText" class="textarea" placeholder="Ecrivez un message ..." rows="2"></textarea>
      </div>
      <a :class="`button is-primary submit-button ${isSubmitting ? 'is-loading' : ''}`" @click="submitComment">
        Envoyer&nbsp;
        <i class="mdi mdi-send" />
      </a>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';

export default {
  props: {
    channel: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      newCommentText: '',
    }
  },
  watch: {
    channel: {
      handler: 'fetchComments',
      immediate: true,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.login.user,
    }),
    comments () {
      if(this.$store.state.abstractElements.objects && this.$store.state.abstractElements.objects.comments_tab)
        return this.$store.state.abstractElements.objects.comments_tab.objects;
      else
        return [];
    }
  },
  methods: {
    async fetchComments () {
      console.log('fetchComments')
      this.isLoading = true;
      const res = await this.$store.dispatch(`abstractElements/fetchObjects`, {
        collection: 'system_comments',
        destinationBucket: 'comments_tab',
        query: { channel: this.channel },
        sort: { date: 1 },
      });
      console.log('=>', this.$store.state.abstractElements.objects.comments_tab.objects);
      this.isLoading = false;
    },
    submitComment () {
      this.isSubmitting = true;
      this.$store.dispatch(`abstractElements/createObject`, {
        collection: 'system_comments',
        destinationBucket: 'comments_tab',
        object: {
          author: this.user.username,
          message: this.newCommentText,
          channel: this.channel,
          date: dayjs().format('YYYY-MM-DD HH:mm:ss')
        },
      }).then((payload) => {
        this.newCommentText = '';
        this.isSubmitting = false;
      });
    }
  }
};
</script>
<style scoped>
.message-bubble {
  position: relative;
  background: whitesmoke;
  border-radius: 30px;
  margin: 20px;
  padding: 20px;
  padding-top: 40px;
  padding-left: 60px;
}

.message-bubble .author {
  position: absolute;
  top: -14px;
  left: 20px;
  font-weight: bold;
  display: flex;
  align-items:  flex-end;
}
.message-bubble .author .name {
  margin-left: 10px;
}
.message-bubble .author img {
  width: 40px;
  border-radius: 100%;
}

.message-bubble .date {
  position: absolute;
  top: 10px;
  right: 20px;
}
.submit-button {
  float: right;
  margin-top: 10px;
}
.new-message-content {
  margin-left: 20px;
  margin-right: 20px;
}
.textarea {
  border-radius: 20px;
}
</style>
