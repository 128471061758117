/*
 * CSS des champs à editer dans le fichier ./vfgfieldstyles.css
 */

export default [
  {
    value: 'no-label',
    description: 'No label for the field',
  }, {
    value: 'quarter-width',
    description: 'Takes a quarter of the horizontal space',
  }, {
    value: 'tier-width',
    description: 'Takes a third of the horizontal space',
  }, {
    value: 'half-width',
    description: 'Takes half of the horizontal space',
  }, {
    value: 'two-tiers-width',
    description: 'Takes two thirds of the horizontal space',
  }, {
    value: 'three-quarters-width',
    description: 'Takes three quarters of the horizontal space',
  }, {
    value: 'one-fifth-width',
    description: 'Takes one fifth of the horizontal space',
  }, {
    value: 'label-on-top',
    description: 'Label on top',
  }, {
    value: 'expand-label',
    description: 'Expand label as much as possible',
  }, {
    value: 'no-margin',
    description: 'Remove the margin around the field',
  }, {
    value: 'underline',
    description: 'display a line below the label and the field',
  }, {
    value: 'centered-field-with-large-label',
    description: 'Mostly used for checkboxes',
  }, {
    value: 'big-label',
    description: 'scales the label to 120%',
  }, {
    value: 'huge-label',
    description: 'scales the label to 150%',
  }, {
    value: 'centered-label',
    description: 'Aligns the label to the center',
  }, {
    value: 'inverted-label',
    description: 'Sets the label as white on a black background',
  }, {
    value: 'label-tiny',
    description: 'Lower the width of the label',
  }, {
    value: 'label-blue',
    category: 'Couleurs'
  }, {
    value: 'label-green',
    category: 'Couleurs'
  }, {
    value: 'label-yellow',
    category: 'Couleurs'
  }, {
    value: 'label-red',
    category: 'Couleurs'
  }, {
    value: 'background-blue',
    category: 'Couleurs'
  }, {
    value: 'background-green',
    category: 'Couleurs'
  }, {
    value: 'background-yellow',
    category: 'Couleurs'
  }, {
    value: 'background-red',
    category: 'Couleurs'
  }, {
    value: 'sub-field'
  }
];
