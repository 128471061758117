<template>
  <div>
    <b-loading :is-full-page="false" :active="isLoading" :can-cancel="false" />
    <div>
      <v-runtime-template v-if="tab.template && tab.template !== ''" :template="`<span>${tab.template}</span>`" />
    </div>
    <div style="clear: both" />
    <ArrayBlock :config="tab" v-if="tab.type === 'list'" ref="array" locked hideEditButton hidePagination disableCellFiltering hideTitleBar showEverything/>
    <b-message v-if="tab.type === 'list' && !isLoading && (listElements === undefined || listElements.length === 0)">
      Pas d'éléments à afficher ici...
    </b-message>
    <div v-if="tab.type === 'comments' && element._id">
      <Comments :channel="`record/${config.collection}/${element._id}`" />
    </div>
  </div>
</template>
<script>
import VRuntimeTemplate from 'v-runtime-template';
import ArrayBlock from '@/components/blocks/Array';
import Comments from '@/components/Comments/Comments';

export default {
  components: {
    VRuntimeTemplate,
    ArrayBlock,
    Comments,
  },
  props: {
    element: {
      type: Object,
      required: true
    },
    tab: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      isLoading: false,
      listElements: [],
    };
  },
  watch: {
    'tab.type': {
      handler (v) {
        if (v === 'list') {
          this.fetchObjects();
        }
      },
      immediate: true,
    }
  },
  computed: {
    schema () {
      if (this.tab.schema) {
        return this.getSchemaByName(this.tab.schema);
      }
    }
  },
  methods: {
    async fetchObjects() {
      console.log('fetchObjects', this.tab.query);
      this.isLoading = true;
      const bucket = `tab_list_${this.tab.collection}`;
      const res = await this.$store.dispatch(`${this.tab.storeModule || 'abstractElements'}/fetchObjects`, {
        collection: this.tab.collection,
        destinationBucket: bucket,
        query: this.tab.query,
        sort: this.tab.sort,
        additionnalTemplateVars: { element: this.element }
      });
      this.isLoading = false;

      this.listElements = this.$store.state.abstractElements.objects[bucket].objects;
      if(this.$refs.array) {
        console.log('popupate array');
        this.$refs.array.allElements = {
          objects: this.listElements,
          filteredObjects: this.listElements,
          isLoading: false
        };
      }
    },
    getSchemaByName(schemaName) {
      if (!this.$store.state.abstractElements.objects || !this.$store.state.abstractElements.objects.schemas) {
        return {};
      }

      const schemas = this.$store.state.abstractElements.objects.schemas.objects;
      if (!schemas) { return {}; }
      return schemas.find((s) => s.name === schemaName) || {};
    },
  },

};
</script>
<style scoped>
.table {
  margin: 0;
}
</style>
