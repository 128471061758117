<template>
  <b-message type="is-danger" has-icon>
    <p>
      {{ i18n.error_while_fetching_document }}
    </p>
    <button class="button" @click="$emit('retry')">
      <i class="mdi mdi-refresh" />
      {{ i18n.retry }}
    </button>
  </b-message>
</template>
<script type="text/javascript">
import i18n from 'i18n/components/modals/EditModalDocumentLoadingError.json';

export default {
  data() {
    return { i18n };
  },
};
</script>
