<template>
  <div class="document-history-table">
    <h3 class="title is-3">
      {{ i18n.last_changes }}
    </h3>
    <b-taginput
      v-model="watchedKeys"
      :data="availableKeys"
      :placeholder="i18n.watch_a_key"
      :allow-new="true"
      :open-on-focus="true"
      autocomplete
      ellipsis
      icon="magnify"
      class="no-print"
      @typing="getFilteredAvailableKeys"
    />
    <b-table
      :data="historyEntries"
      :row-class="rowClass"
      class="table is-fullwidth"
      detailed
      :mobile-cards="false"
    >
      <b-table-column v-slot="props" :width="140" field="type" label="Type" width="40">
        <i
          :class="{
          mdi: true,
          'mdi-close-circle': props.row.type === 'delete',
          'mdi-plus-circle': props.row.type === 'create',
          'mdi-pencil': props.row.type === 'update'
          }"
        />
      </b-table-column>
      <b-table-column v-slot="props" :width="140" field="user" label="User">
        <span style="font-size: 90%">
          {{ props.row.user }}
        </span>
      </b-table-column>
      <b-table-column v-slot="props" :width="160" field="date" label="Date">
        <span class="tag">
          <i class="mdi mdi-calendar" style="margin-right: 3px"/>
          {{ props.row.date }}
        </span>
      </b-table-column>
      <b-table-column v-slot="props" label="Changements">
        <span class="changes">
          <b-tooltip
            v-for="(c,i) in changesFor(props.index)"
            :key="i"
            :label="c.tooltip"
            multilined
          >
            <span :class="`tag ${c.type === 'create' ? 'is-success': ''} ${c.type === 'update' ? 'is-warning': ''} ${c.type === 'delete' ? 'is-danger': ''}`">
              {{c.key}}
            </span>
          </b-tooltip>
        </span>
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <b-tabs>
          <b-tab-item label="Diff">
            <vue-json-compare :old-data="showLastDocumentKeys(props.index)" :new-data="showDocumentKeys(props.row.document)" />
          </b-tab-item>
          <b-tab-item label="Ancienne valeur">
            <vue-json-compare :old-data="showLastDocumentKeys(props.index)" :new-data="showLastDocumentKeys(props.index)" />
          </b-tab-item>
          <b-tab-item label="Nouvelle valeur">
            <vue-json-compare :old-data="showDocumentKeys(props.row.document)" :new-data="showDocumentKeys(props.row.document)" />
          </b-tab-item>
        </b-tabs>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p style="font-size: 300%">
              <i class="mdi mdi-help-circle" />
            </p>
            <p>{{ i18n.no_history_log_for_this_document }}</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>
<script>
import vueJsonCompare from 'vue-json-compare';
            import Api from '@/core/Api';
            import i18n from 'i18n/components/DocumentHistory.json';

            export default {
              name: 'DocumentHistory',
              components: {
                vueJsonCompare,
              },
              props: {
                documentId: {
                  type: String,
                  default: undefined,
                },
                schema: {
                  type: Object,
                  default: undefined,
                },
                historyEntries: {
                  type: Array,
                  required: true,
                }
              },

              data() {
                return {
                  i18n,
                  lastDocumentKeys: undefined,
                  allAvailableKeys: (this.schema && this.schema.fields) ? this.schema.fields.map((f) => f.model) : [],
                  availableKeys: (this.schema && this.schema.fields) ? this.schema.fields.map((f) => f.model) : [],
                  watchedKeys: undefined,
                };
              },

              methods: {
                getFilteredAvailableKeys(text) {
                  this.availableKeys = this.allAvailableKeys.filter((option) => option
                    .toString()
                    .indexOf(text) >= 0
                    && this.watchedKeys.indexOf(option) === -1);
                },
                rowClass(doc, index) {
                  if (this.watchedKeys && this.watchedKeys !== '' && this.watchedKeys.length !== 0) {
                    const watchedKeys = [...this.watchedKeys];
                    if (this.schema && this.schema.fields) {
                      for (const field of this.schema.fields) {
                        if (watchedKeys.includes(field.label)) {
                          watchedKeys.push(field.model);
                        }
                      }
                    }
                    if (index === 0) {
                      return 'highlighted';
                    }
                    const oldDoc = this.historyEntries[index - 1];
                    for (const key of watchedKeys) {
                      if (oldDoc.document[key] !== doc.document[key]) {
                        return 'highlighted';
                      }
                    }
                    return 'not-highlighted';
                  }
                  return 'highlighted';
                },
                showDocumentKeys(doc) {
                  const res = {};
                  const keys = Object.keys(doc);

                  for (let i = 0; i < keys.length; i++) {
                    if (keys[i] !== '_id' && keys[i] !== '_metadatas') {
                      res[keys[i]] = doc[keys[i]];
                    }
                  }

                  this.lastDocumentKeys = res;
                  return res;
                },
                changesFor(index) {
                  if (index > 0) {
                    const res = [];
                    const previous = this.historyEntries[index - 1].document;
                    const current = this.historyEntries[index].document;
                    const previousKeys = Object.keys(previous);
                    const currentKeys = Object.keys(current);

                    for (const k of currentKeys) {
                      if (k !== '_metadatas') {
                        if (previous[k] === undefined) {
                          res.push({ type: 'create', key: k, tooltip: JSON.stringify(current[k]) });
                        } else if (JSON.stringify(previous[k]) !== JSON.stringify(current[k])) {
                          res.push({
                            type: 'update',
                            key: k,
                            tooltip: `${`${JSON.stringify(previous[k])} ⇒ ${JSON.stringify(current[k])}`}`,
                          });
                        }
                      }
                    }
                    for (const k of previousKeys) {
                      if (current[k] === undefined) {
                        res.push({ type: 'delete', key: k });
                      }
                    }

                    return res;
                  }
                  return [];
                },
                showLastDocumentKeys(index) {
                  if (index > 0) {
                    return this.showDocumentKeys(this.historyEntries[index - 1].document);
                  }
                  return {};
                },
              },
            };
</script>
<style>
.document-history-table {
  padding: 20px;
}
.document-history-table .not-highlighted td {
  opacity: 0.3;
}
</style>
<style scoped>
.tag {
  margin-left: 2px;
  margin-right: 2px;
}
.document-history-table >>> .c-json-outter {
  height: 0;
  margin-top: -30px;
  margin-bottom: -14px;
}
.document-history-table >>> .detail-container, .document-history-table >>> .detail > td {
  padding: 0 !important;
}
</style>
