<template>
  <Modal
    :class="`main-edit-modal edit-modal ${isMobile ? 'mobile': 'desktop'} ${!!editedModel ? 'active' : ''}`"
    :active="!!editedModel"
    :width="1600"
    :canCancel="['escape', 'button']"
    @close="callClose"
  >
    <template slot="header">
      <span v-if="PermissionManager.can(actions.can_see_editmodal_recordpage_link)">
        <button class="button" @click="goToRecordPage">
          <i class="mdi mdi-file-document-box-search" />
        </button>
        &nbsp;
        &nbsp;
      </span>
      <span class="modal-title">
        <span v-if="error" class="tag is-danger">
          {{ error }}
        </span>
      </span>
      <span class="modal-modes-buttons field has-addons">
        <EditModalTabs :schema="schema" :edit-mode="editMode" :edited-tab="editedTab" :show-history="showHistory" @changeMode="editMode = $event"/>
        <b-tooltip v-if="PermissionManager.can(actions.can_edit_schemas)" label="Nouvel onglet" position="is-bottom" class="add-tab-button">
          <a @click="newModalTab">
            <i class="mdi mdi-plus" />
          </a>
        </b-tooltip>
      </span>
      <a @click="printToPdf">
        <i class="mdi mdi-printer" />
      </a>
      <CollaboratingUsersPreview v-if="VUE_APP_HAS_COLLABORATING_MODE && !isMobile && editedModel && editedModel._id" :lock-name="`see/document/${editedModel._id}`" style="margin-left: 20px"/>
    </template>
    <b-loading :is-full-page="false" :active="documentLoading" :can-cancel="false" />
    <div v-if="errorLoadingDocument">
      <EditModalDocumentLoadingError style="margin: 20px;" @retry="loadDocument" />
    </div>
    <div v-else-if="editedModel && !documentLoading" style="height: 100%">
      <codemirror
        v-if="editMode ==='code'"
        ref="editor"
        v-model="code"
        :options="editorOptions"
        class="raw-json-editor"
      />
      <div v-if="editMode.startsWith('customTab_')">
        <CustomTabEditor
          :tab="schema.tabs[parseInt(editMode.split('_')[1])]"
          @enterEditMode="editedTab = parseInt(editMode.split('_')[1])"
          @leaveEditMode="editedTab = undefined"
          @save="saveSchema"
          @delete="deleteTab(parseInt(editMode.split('_')[1]))"
        />
        <CustomTab :tab="schema.tabs[parseInt(editMode.split('_')[1])]" :element="modelCopyUnedited" :config="config" />
      </div>
      <div v-show="editMode ==='table'" class="editor table" style="height:100%">
        <div
          v-if="schema"
          v-show="!isPortalActive"
          :class="draggingField ? 'dragging-field': ''"
        >
          <b-message v-if="!preparedSchema.fields || preparedSchema.fields.length === 0" type="is-warning" has-icon icon="file-hidden" >
            Ce formulaire est vide!
          </b-message>
          <vue-form-generator
            ref="form"
            :schema="preparedSchema"
            :model="modelCopy"
            class="form main-form"
            tag="div"
          />
          <MiniPropertyEditor
            v-if="editedSchemaProperty && PermissionManager.can(actions.can_edit_schemas)"
            :value="editedSchemaProperty"
            :property-id="editedSchemaPropertyId"
            :all-properties="schema.fields"
            class="mini-property-editor"
            :style="`
            top: ${miniPropertyEditorTop};
            left: ${miniPropertyEditorLeft};
            width: ${miniPropertyEditorWidth};
            height: ${miniPropertyEditorHeight};
            `"
            @openAdvancedPropertyEditor="openAdvancedPropertyEditorForField(f)"
            @onClose="exitSchemaPropertyEdition"
            @onDelete="deleteSchemaProperty"
            @onSave="onSaveProperty"
            @dragStart="startDragField"
            @dragEnd="stopDragField"
          />
          <span class="field has-addons newPropertyField no-print">
            <p class="control">
              <b-button v-if="!isMobile && PermissionManager.can(actions.can_edit_schemas)" class="newPropertyButton" @click="newSchemaProperty">
                <i class="mdi mdi-plus" />
                {{ i18n.new_property }}
              </b-button>
            </p>
            <p class="control">
              <b-button v-if="!isMobile && PermissionManager.can(actions.can_edit_schemas)" class="newGroupButton" @click="newSchemaGroup">
                <i class="mdi mdi-plus" />
                Nouveau groupe
              </b-button>
            </p>
          </span>
        </div>
        <span v-else class="form">
          <div class="placeholder">
            {{ i18n.no_schema_defined_for_this_model }}
          </div>
        </span>
        <div :style="isPortalActive ? 'height:100%': 'height: 60px'">
          <portal-target name="portalComplexField" @change="isPortalActive = $event" :style="isPortalActive ? 'height:100%': ''"/>
        </div>
      </div>
      <div v-show="editMode ==='schemaProperty'" style="padding: 20px">
        <a @click="exitSchemaPropertyEdition">
          <i class="mdi mdi-arrow-left" />
          {{ i18n.back }}
        </a>
        <h3 v-if="editedSchemaProperty" class="title is-3">
          {{ i18n.edit_property_schema }}
          <span class="tag is-large">
            {{ editedSchemaProperty.label || editedSchemaProperty.model }}
          </span>
        </h3>
        <div v-if="editedSchemaProperty" class="content">
          <SchemaPropertyEditor v-model="editedSchemaProperty" :schema="schema" />
        </div>
      </div>
      <div v-show="editMode ==='metadatas' && PermissionManager.can(actions.can_see_editmodal_metadata)" class="editor metadatas">
        <div>
          <portal-target name="portalComplexField" @change="isPortalActive = $event" />
        </div>
        <div class="content">
          <fieldset class="form">
            <div class="form-group field-input field-id">
              <label for="owner">
                <span>
                  <i class="mdi mdi-key" />
                  ID
                </span>
              </label>
              <div class="field-wrap">
                <div class="wrapper">
                  <b-input :value="editedModel._id" type="text" class="form-control" disabled />
                </div>
              </div>
            </div>
             <div class="form-group field-input field-id">
              <label for="owner">
                <span>
                  {{ i18n.group }}
                </span>
              </label>
              <div class="field-wrap">
                <div class="wrapper">
                  <InputGroup v-model="editedModel.group" :collection="config.collection" />
                </div>
              </div>
            </div>
            <vue-form-generator
              :schema="metadatasSchema"
              :model="editedModel._metadatas || {}"
              class="form"
              tag="div"
            />
          </fieldset>
        </div>
        <!--<RecordQRCode :panel="config" />-->
      </div>
      <div v-if="showHistory" v-show="editMode ==='history' && PermissionManager.can(actions.can_see_editmodal_history)" class="editor history">
        <DocumentHistory :document-id="editedModel._id" :schema="schema" :history-entries="historyEntries" />
      </div>
    </div>
    <template slot="footer">
      <div style="width: 100%; display: flex; justify-content: space-between;">
        <button
          v-if="showDeleteButton && PermissionManager.can(actions.can_see_editmodal_delete_button)"
          :class="`button is-danger ${deleteLoading ? 'is-loading' : ''}`"
          @click="$emit('onDelete', editedModel)"
        >
          {{ i18n.delete }}
        </button>
        <span class="field right-footer-field field has-addons" style="display: flex">
          <p v-if="hasAdvancedFields && editMode ==='table'" class="control" style="margin-top: 8px; margin-right: 12px">
            <b-switch id="edit-modal-advanced-switch" v-model="advanced">
              {{ i18n.advanced }}
            </b-switch>
          </p>
          <p class="control">
            <button class="button" @click="$emit('close')">
              {{ i18n.cancel }}
            </button>
          </p>
          <p class="control">
            <button :class="`button is-primary ${saveLoading ? 'is-loading' : ''}`" @click="save">
              {{ i18n.save }}
            </button>
          </p>
        </span>
      </div>
    </template>
  </Modal>
</template>
<script>
import { isMobile } from 'mobile-device-detect';
import { mapState } from 'vuex';
import mousetrap from 'mousetrap';
import 'mousetrap/plugins/global-bind/mousetrap-global-bind';

import 'vue-form-generator/dist/vfg.css';

import JSON5 from 'json5';

import '@/core/vfgfields';
import { debounce } from 'vue-debounce';
import DocumentHistory from '@/components/DocumentHistory';
import SchemaPropertyEditor from '@/components/SchemaPropertyEditor';

import Modal from '@/components/modals/Modal';
import CustomTabEditor from '@/components/modals/EditModal/CustomTabEditor';
import CustomTab from '@/components/modals/EditModal/CustomTab';
import CollaboratingUsersPreview from '@/components/CollaboratingUsersPreview';

import codemirror from '@/components/ui/Codemirror';
import PermissionManager from '@/permissions/PermissionManager';
import HasPreparableSchema from '@/core/mixins/HasPreparableSchema';

import deepClone from '@/core/utils/deepClone';
import MiniPropertyEditor from '@/components/MiniPropertyEditor';
import Api from '@/core/Api';
import InputGroup from '@/components/ui/InputGroup';
import actions from '@/permissions/actions';
import i18n from 'i18n/components/modals/EditModal.json';
import { findAndReplaceIf } from 'find-and-replace-anything';
import EditModalDocumentLoadingError from './EditModalDocumentLoadingError';
import redirect from '@/core/utils/redirect';

import { ToastProgrammatic as Toast } from 'buefy';

const _listeners = [];

const addEventListener = function (target, type, listener) {
  _listeners.push({ target, type, listener });
  target.addEventListener(type, listener);
};

const removeEventListeners = function (target, targetType) {
  for (const listener of _listeners) {
    if (listener.target === target && listener.type === targetType) {
      target.removeEventListener(listener.type, listener.listener);
    }
  }
};

export default {
  components: {
    EditModalTabs: () => import(/* webpackChunkName: "EditModalTabs" */ '@/components/modals/EditModal/EditModalTabs'),
    RecordQRCode: () => import(/* webpackChunkName: "RecordQRCode" */ '@/components/modals/EditModal/RecordQRCode'),
    Modal,
    codemirror,
    DocumentHistory,
    SchemaPropertyEditor,
    MiniPropertyEditor,
    InputGroup,
    EditModalDocumentLoadingError,
    CollaboratingUsersPreview,
    CustomTabEditor,
    CustomTab,
  },
  mixins: [HasPreparableSchema],
  props: {
    title: {
      type: String,
      default: 'Edit',
    },
    editedModel: {
      type: Object,
      default: undefined,
    },
    config: {
      type: Object,
      default: undefined,
    },
    showHistory: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    guessSchemaName: {
      type: Boolean,
      default: false,
    },
    fetchObjectOnBackend: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const cmOptions = {
      tabSize: 4,
      mode: { name: 'javascript', json: true },
      theme: 'base16-dark',
      lineNumbers: true,
      lineWrapping: true,
      line: true,
      autofocus: true,
      keyMap: 'sublime',
      autoCloseBrackets: true,
      extraKeys: {
        'Ctrl-Enter': () => {
          this.save();
        },
        'Ctrl-Alt-1': () => {
          this.editMode = 'code';
        },
        'Ctrl-Alt-2': () => {
          this.editMode = 'table';
        },
      },
    };

    const metadatasSchema = {
      fields: [{
        type: 'text',
        label: i18n.owner,
        model: 'owner',
      }, {
        type: 'dateReadOnly',
        label: i18n.creation_date,
        model: 'creationDate',
      }, {
        type: 'dateReadOnly',
        label: i18n.last_modification_date,
        model: 'lastModificationDate',
      }],
    };

    return {
      i18n,
      documentLoading: false,
      hasAdvancedFields: false,
      draggingField: false,
      advanced: false,
      isMobile,
      debouncedDropField: debounce((e, formGroups, displayedFields) => {
        this.dropField(e, formGroups, displayedFields);
      }, 50),
      dragEventListeners: [],
      editedTab: undefined,
      lockCheckInterval: undefined,
      lock: undefined,
      miniPropertyEditorTop: undefined,
      miniPropertyEditorLeft: undefined,
      miniPropertyEditorWidth: undefined,
      miniPropertyEditorHeight: undefined,
      actions,
      PermissionManager,
      editMode: PermissionManager.can(actions.can_see_editmodal_code) ? 'code' : 'table',
      editedSchemaProperty: undefined,
      editedSchemaPropertyId: undefined,
      editedSchemaPropertyOriginal: undefined,
      editedSchemaPropertyModel: undefined,
      modelCopy: undefined,
      historyEntries: [],
      modelCopyUnedited: undefined,
      modelMetadatas: undefined,
      schema: undefined,
      error: undefined,
      errorLoadingDocument: false,
      mainBodyScrollOldPosition: 0,
      isPortalActive: false,
      editorOptions: cmOptions,
      code: '',
      deleteLoading: false,
      saveLoading: false,
      metadatasSchema,
      VUE_APP_HAS_COLLABORATING_MODE: process.env.VUE_APP_HAS_COLLABORATING_MODE,
    };
  },
  computed: {
    ...mapState({
      currentWorkgroup: (state) => state.login.currentWorkgroup,
      userDetails: (state) => state.login.userDetails,
      user: (state) => state.login.user,
      globalConfig: (state) => state.config.config,
    }),
    schemaName() {
      console.log('schemaName CP', this.guessSchemaName, this.modelMetadatas);
      if (this.guessSchemaName) {
        if (this.modelMetadatas) {
          return this.modelMetadatas.schemaName;
        }
        return undefined;
      }
      if (this.config) {
        return this.config.schema;
      }
    },
    titleSuffix() {
      if (this.modelCopy && this.schema && this.schema.fields) {
        const identifier = this.schema.fields.filter((f) => f.isIdentifier === true)[0];
        if (identifier && this.modelCopy[identifier.model]) {
          return this.modelCopy[identifier.model];
        }
      }
      if (this.schemaName) {
        return this.schemaName;
      }
    },
  },
  watch: {
    draggingField(v) {
      const formGroups = this.$refs.form.$el.querySelectorAll('.form-group');
      if (v) {
        const displayedFields = this.$refs.form.fields.filter((f) => this.$refs.form.fieldVisible(f));

        for (let i = 0; i < formGroups.length; i++) {
          removeEventListeners(formGroups[i], 'dragover');
          removeEventListeners(formGroups[i], 'drop');

          const dropEventListener = (e) => {
            this.debouncedDropField(e, formGroups, displayedFields);
          };
          const dragoverEventListener = (e) => {
            const hoveredFormGroups = this.$refs.form.$el.querySelectorAll('.form-group.drag-over');

            for (let j = 0; j < hoveredFormGroups.length; j++) {
              hoveredFormGroups[j].classList.remove('drag-over');
            }
            let hoveredFormGroup;
            if (e.target.classList.contains('.form-group')) {
              hoveredFormGroup = e.target;
            } else {
              hoveredFormGroup = e.target.closest('.form-group');
            }
            if (hoveredFormGroup) {
              hoveredFormGroup.classList.add('drag-over');
            }
            e.preventDefault();
          };
          this.dragEventListeners.dragover = dragoverEventListener;
          this.dragEventListeners.drop = dropEventListener;
          addEventListener(formGroups[i], 'dragover', dragoverEventListener);
          addEventListener(formGroups[i], 'drop', dropEventListener);
        }
      } else {
        for (const formGroup of formGroups) {
          removeEventListeners(formGroup, 'dragover');
          removeEventListeners(formGroup, 'drop');
        }
      }
    },
    editedModel: {
      async handler(v) {
        if (v) {
          this.modelMetadatas = undefined;
          this.registerShortcuts();
          if (this.fetchObjectOnBackend) {
            const loadStatus = await this.loadDocument();
            if (loadStatus.success === false) {
              Toast.open({ message: `Could not load object (${loadStatus.error})`, type: 'is-danger' });
              throw new Error(loadStatus.error);
            }
          } else {
            this.modelCopy = deepClone(v);
            this.modelMetadatas = this.modelCopy._metadatas;
          }

          this.schema = this.getSchemaByName(this.schemaName);
          console.log('????', this.schemaName, this.schema);
          this.preparedSchema = this.prepareSchema(this.schema);

          if (this.schema) {
            this.editMode = 'table';
          } else if (PermissionManager.can(actions.can_see_editmodal_code)) {
            this.editMode = 'code';
          } else {
            this.editMode = 'table';
          }
          if (this.$refs.editor && this.$refs.editor.cminstance) {
            this.$refs.editor.cminstance.focus();
          }
          if (this.config.collection && v._id) {
            this.checkLock();
            if (this.lockCheckInterval) {
              clearInterval(this.lockCheckInterval);
            }
            this.lockCheckInterval = setInterval(() => { this.checkLock(); }, 30000);
            Api.get(`/system_logs?query={"document._id": "${this.modelCopy._id}"}&sort={"date":1}`).then((response) => {
              const { documents } = response.data;
              const uniqueChecker = {};
              this.historyEntries = documents.filter((d) => {
                delete d._id;
                d.date = new Date(d.date).toLocaleString();
                const strValue = JSON.stringify(d);
                if (uniqueChecker[strValue] === undefined) {
                  uniqueChecker[strValue] = true;
                  return true;
                }
                return false;
              });
            });
          }
        } else {
          clearInterval(this.lockCheckInterval);
          this.lockCheckInterval = undefined;
          this.unregisterShortcuts();
        }
        this.isPortalActive = false;
      },
      immediate: true,
    },
    code: {
      handler(v) {
        try {
          this.modelCopy = {
            _id: this.editedModel._id,
            ...JSON5.parse(v),
          };
          this.error = undefined;
        } catch (e) {
          this.error = e;
        }
      },
    },
    editMode() {
      this.editedTab = undefined;
      this.isPortalActive = false;
    },
    isPortalActive(v) {
      if (v) {
        this.mainBodyScrollOldPosition = this.$el.querySelector('.modal-card-body').scrollTop;
      } else {
        this.$nextTick(() => {
          this.$el.querySelector('.modal-card-body').scroll(0, this.mainBodyScrollOldPosition);
        });
      }
    },
  },
  beforeUnmount() {
    this.editedTab = undefined;
    if (this.lockCheckInterval) {
      clearInterval(this.lockCheckInterval);
      this.lockCheckInterval = undefined;
    }
  },
  methods: {
    deleteTab(index) {
      this.schema.tabs.splice(index, 1);
      this.saveSchema();
    },
    newModalTab () {
      if (this.schema.tabs === undefined) {
        this.schema.tabs = [];
      }

      this.schema.tabs.push({});
      this.saveSchema();
    },
    async printToPdf () {
      document.querySelectorAll('.pdf-print-preview').forEach(e => e.parentNode.removeChild(e));

      const temp = document.createElement('div');
      temp.classList.add('pdf-print-preview');
      temp.classList.add('edit-modal');
      document.body.classList.add('desktop');
      temp.appendChild (this.$el.querySelector('.modal-card-body').cloneNode(true));
      console.log(this.$el.querySelector('.modal-card-body').cloneNode(true));
      document.getElementById('app').classList.add('no-print');
      document.body.appendChild(temp);
      window.print();
      document.getElementById('app').classList.remove('no-print');
      document.body.classList.remove('desktop');
    },
    async checkLock() {
      if (this.config && this.config.collection && this.editedModel._id) {
        const res = await Api.post(`system_locks/${this.config.collection}/${this.editedModel._id}`);
        const lock = res.data.documents[0];
        delete lock.users[this.user.username];
        this.lock = lock;
      } else {
        this.lock = undefined;
      }
    },
    callClose() {
      this.$nextTick(() => {
        if (this.editedModel) {
          this.$emit('close');
        }
      });
    },
    changeWorkgroup(event) {
      if (event === '') {
        this.modelCopy.workgroup = undefined;
      } else {
        this.modelCopy.workgroup = event;
      }
      this.modelCopy = { ...this.modelCopy };
      console.log('changeWorkgroup', event, this.modelCopy.workgroup);
    },
    async loadDocument() {
      try {
        let editedModel = { ...this.editedModel };
        if (this.editedModel._id) {
          this.documentLoading = true;
          let payload;
          try {
            this.errorLoadingDocument = false;
            payload = await Api.get(`/${this.config.collection}/${this.editedModel._id}`, {
              params: {
                workgroup: this.currentWorkgroup,
              },
            });
          } catch (e) {
            console.error(e);
            this.errorLoadingDocument = true;
            // this.$emit('close');
            if (e.response && e.response.data && e.response.data.error && e.response.data.error.message === 'UnauthorizedError: No authorization token was found') {
              window.location = '#/login';
            }
          }
          this.documentLoading = false;
          if (payload.data.success === false) {
            console.log('error at loading document', payload.data);
            // FIXME add error handling
          }
          this.modelCopyUnedited = deepClone(payload.data.documents[0]);
          editedModel = payload.data.documents[0];
          delete editedModel._id;
          this.modelMetadatas = editedModel._metadatas;
          delete editedModel._metadatas;
        }
        this.code = JSON5.stringify(editedModel, null, 2);
        this.modelCopy = editedModel;
        this.modelCopy = editedModel;
        this.advanced = false;
        return { success: true };
      } catch (e) {
        console.error(e);
        return { success: false, error: e};
        this.documentLoading = false;
      }
    },
    onSaveProperty({ value, propertyId }) {
      console.log('onSaveProperty', value, propertyId);
      const _id = this.editedSchemaProperty._id;
      for (let k of Object.keys(this.editedSchemaProperty)) {
        delete this.editedSchemaProperty[k];
      }
      console.log('>>> delete ok');
      for (let k of Object.keys(value)) {
        this.editedSchemaProperty[k] = value[k];
      }
      this.editedSchemaProperty._id = _id;
      //this.editedSchemaProperty = value;
      this.saveSchema(_id);
    },
    async dropField(e, formGroups, displayedFields) {
      let hoveredFormGroup;
      let dropTargetId;
      let schema = this.preparedSchema;
      if (e.target.classList.contains('form-group')) {
        dropTargetId = e.target.__vue__.$props.field._id;
        if (dropTargetId === this.editedSchemaProperty._id) {
          dropTargetId = undefined;
        }
      } else if (e.target.closest('.form-group')) {
        dropTargetId = e.target.closest('.form-group').__vue__.$props.field._id;
        if (dropTargetId === this.editedSchemaProperty._id) {
          dropTargetId = undefined;
        }
      } else {
        console.log('no parent form group, abort with an error toast');
      }
      if (dropTargetId) {
        this.deleteSchemaProperty(true);
        schema = findAndReplaceIf(
          schema,
          (o) => {
            if (Array.isArray(o)) {
              o.map((f) => {
                if (f._id) {
                  delete f.buttons;
                  delete f.attributes;
                }
              });

              const dropTargetSearch = o.findIndex((f) => f._id === dropTargetId);
              if (dropTargetSearch !== -1) {
                o.splice(dropTargetSearch, 0, this.editedSchemaProperty);
              }
            }
            return o;
          }, { checkArrayValues: true },
        );
      }
      await this.$store.dispatch('abstractElements/saveObject', {
        collection: 'schemas',
        object: schema,
      });
      this.schema = schema;
      this.preparedSchema = this.prepareSchema(schema);

      setTimeout(() => {
        let foundField;
        findAndReplaceIf(
          schema,
          (o) => {
            if (Array.isArray(o)) {
              const dropTargetSearch = o.findIndex((f) => f._id === dropTargetId);
              if (dropTargetSearch !== -1) foundField = o[dropTargetSearch - 1];
            }
            return o;
          }, { checkArrayValues: true },
        );
        if (foundField) {
          this.editedSchemaPropertyOriginal = foundField;
          this.openPropertyEditorForField(foundField);
        }
      }, 100);
      this.exitSchemaPropertyEdition();
    },
    openPropertyEditorForField(f) {
      let fieldEl = this.$el.querySelector(`*[data-field-for="${f._id}"]`);
      const form = this.$el.closest('.editor > div > .vue-form-generator');
      if (fieldEl) {
        fieldEl = fieldEl.parentElement;
        let parentEl = fieldEl.parentElement;
        let totalTop = 0;
        const totalLeft = 0;
        while (parentEl && !parentEl.classList.contains('main-form')) {
          totalTop += parentEl.offsetTop;
          parentEl = parentEl.parentElement.closest('.form-group');
        }
        this.miniPropertyEditorTop = `${totalTop}px`;
        this.miniPropertyEditorLeft = `${fieldEl.parentElement.offsetLeft}px`;
        this.miniPropertyEditorWidth = `${fieldEl.parentElement.offsetWidth}px`;
        this.miniPropertyEditorHeight = `${fieldEl.parentElement.offsetHeight}px`;
      }

      const editedSchemaProperty = deepClone(f);
      this.editedSchemaPropertyOriginal = f;
      delete editedSchemaProperty.buttons;
      delete editedSchemaProperty.attributes;
      this.editedSchemaProperty = editedSchemaProperty;
      this.editedSchemaPropertyId = this.preparedSchema.fields._id;
      this.editedSchemaPropertyModel = editedSchemaProperty.model;
    },
    openAdvancedPropertyEditorForField(f) {
      this.editMode = 'schemaProperty';
    },
    registerShortcuts() {
      if (this.globalConfig.keyBindings && this.globalConfig.keyBindings.editModalSave) {
        mousetrap.bindGlobal(this.globalConfig.keyBindings.editModalSave, (e) => {
          e.preventDefault();
          this.save();
        });
      }
      if (this.globalConfig.keyBindings && this.globalConfig.keyBindings.editModalQuit) {
        mousetrap.bindGlobal(this.globalConfig.keyBindings.editModalQuit, (e) => {
          e.preventDefault();
          this.$emit('close');
        });
      }
    },

    unregisterShortcuts() {
      if (this.globalConfig.keyBindings && this.globalConfig.keyBindings.editModalSave) {
        mousetrap.unbind(this.globalConfig.keyBindings.editModalSave);
      }
      if (this.globalConfig.keyBindings && this.globalConfig.keyBindings.editModalQuit) {
        mousetrap.unbind(this.globalConfig.keyBindings.editModalQuit);
      }
    },
    newSchemaGroup() {
      this.newSchemaProperty({
        label: 'Nouveau groupe',
        type: 'object',
        schema: { fields: [{ label: 'Nouveau champ' }] },
        styleClasses: [
          'label-on-top',
          'huge-label',
          'inverted-label',
        ],
      });
    },
    async newSchemaProperty(defaultValue) {
      if (!defaultValue || !defaultValue.label) {
        defaultValue = { label: 'Nouveau champ', model: 'new_field' };
      }
      const schema = deepClone(this.schema);
      if (schema.fields === undefined) {
        schema.fields = [];
      }
      schema.fields.push(defaultValue);
      await this.$store.dispatch('abstractElements/saveObject', {
        collection: 'schemas',
        object: schema,
      });
      this.schema = schema;
      this.preparedSchema = this.prepareSchema(this.schema);
    },
    async deleteSchemaProperty(dontSave) {
      if (!this.editedSchemaPropertyOriginal._id) {
        this.editedSchemaPropertyOriginal._id = `fid_${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`;
      }
      // TODO add try catch and revert
      const newSchema = findAndReplaceIf(
        this.preparedSchema,
        (o) => {
          if (Array.isArray(o)) {
            const existingItemSearch = o.findIndex((f) => f._id === this.editedSchemaProperty._id);
            if (existingItemSearch !== -1) {
              o.splice(existingItemSearch, 1);
            }
          }
          return o;
        }, { checkArrayValues: true },
      );
      console.log('newSchema', newSchema);
      if (dontSave !== true) {
        await this.$store.dispatch('abstractElements/saveObject', {
          collection: 'schemas',
          object: newSchema,
        });
      }
      this.schema = newSchema;
      this.preparedSchema = this.prepareSchema(this.schema);

      if (dontSave !== true) {
        this.exitSchemaPropertyEdition();
      }
    },
    async saveSchema(propertyId) {
      console.log('saveSchema');
      let schema = this.preparedSchema;
      let foundIndex;
      schema = deepClone(this.schema);
      if (propertyId && this.editedSchemaProperty) {
        schema = findAndReplaceIf(
          this.preparedSchema,
          (o) => {
            if (typeof o === 'object' && o !== null) {
              console.log('saveSchema ???', o);
              delete o.buttons;
              delete o.attributes;
              if (o._id === propertyId) {
                return this.editedSchemaProperty;
              }
            }
            return o;
          }, { checkArrayValues: true },
        );
      }

      await this.$store.dispatch('abstractElements/saveObject', {
        collection: 'schemas',
        object: schema,
      });
      this.schema = schema;
      this.preparedSchema = this.prepareSchema(this.schema);
      this.exitSchemaPropertyEdition();
    },
    exitSchemaPropertyEdition() {
      this.editMode = 'table';
      this.editedSchemaProperty = undefined;
      this.editedSchemaPropertyOriginal = undefined;
      this.editedSchemaPropertyModel = undefined;
    },
    goToRecordPage() {
      redirect(this.$router, [`/record/${this.config.collection}/${this.editedModel._id}`]);
      this.$emit('close');
    },
    save() {
      if (!this.error) {
        if (!this.$refs.form || this.$refs.form.validate()) {
          const oldModel = deepClone(this.modelCopyUnedited || {});
          delete oldModel._metadatas;
          console.log('modelMetadatas', JSON.stringify(this.modelMetadatas));
          if (JSON.stringify(oldModel) !== JSON.stringify(this.modelCopy)) {
            this.$emit('save', {
              _id: this.editedModel._id,
              ...this.modelCopy,
              _metadatas: this.modelMetadatas,
            });
          } else {
            console.log('unedited, closing');
            this.$emit('close');
          }
        } else {
          if( this.$refs.form) {
            const fields = this.$refs.form.errors ? this.$refs.form.errors.map(e => e.field.label) : ['champ inconnu'];
            Toast.open({ message: `Certains champs sont invalides (${fields.join(',')})`, type: 'is-danger' });
          }
        }
      }
    },
    getSchemaByName(schemaName) {
      if (!this.$store.state.abstractElements.objects || !this.$store.state.abstractElements.objects.schemas) {
        return {};
      }

      const schemas = this.$store.state.abstractElements.objects.schemas.objects;
      if (!schemas) { return {}; }
      return schemas.find((s) => s.name === schemaName) || {};
    },
    startDragField() {
      this.draggingField = true;
    },
    stopDragField() {
      this.draggingField = false;
    },
  },
};
</script>
<style scoped>
.edit-modal .form {
  padding: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

</style>
<style>
.edit-modal .modal-card-head {
  padding: 6px;
  display: flex;
}
.edit-modal .modal-card-body {
  padding: 0;
  height: 85vh;
}
.edit-modal .raw-json-editor .CodeMirror {
  height: 85vh;
}

.edit-modal.mobile .modal-card-body, .edit-modal.mobile .raw-json-editor .CodeMirror {
  height: calc(100% - 57px - 36px);
}

.edit-modal .field-array input[type=button] {
  border: 0;
  background: var(--primary-color);
  color: white;
  border-radius: 4px;
  padding: 8px;
  margin: 8px;
  cursor: pointer;
  font-size: 15px;
}
.edit-modal .field-array input[type=button]:hover {
  background: var(--primary-color-lighter);
}

.edit-modal .form-group .buttons {
  position: absolute;
  left: -64px;
  opacity: 0;
  display: block;
  transition: left 0.2s, opacity 0.2s;
}
.edit-modal .form-group:hover > .field-wrap > .buttons {
  opacity: 100;
  display: block;
  left: -18px;
}

.edit-modal.mobile .modal-close {
  display: none;
}

.edit-modal input[type=checkbox] {
  margin-top: 10px;
}
</style>
<style scoped>
.edit-modal >>> .complex-field-editor {
  margin: 20px;
}
.newPropertyField {
  position: absolute;
  width: calc(100% - 32px);
  height: 36px;
  padding-top: 6px;
  text-align: center;
  margin-left: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 80% 20%;
}
.newPropertyField > p > button {
  width: 100%;
}
.placeholder {
  text-align: center;
  font-size: 24px;
  padding-top: 64px;
  color: #7a7a7a;
}

.right-footer-field {
  margin-left: auto;
}

.mini-property-editor {
  position: absolute;
  width: 100%;
  padding: 4px;
  z-index: 1000;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 20000px rgba(0,0,0,0.5);
  transition: box-shadow 0.5s;
}

.edit-modal >>> .dragging-field .mini-property-editor {
  box-shadow: 0px 0px 0px 20000px rgba(0,0,0,0);
}
.edit-modal >>> .dragging-field .mini-property-editor .overlay {
  pointer-events: none;
}
.edit-modal >>> .dragging-field .drag-handle {
  margin-left: 0px;
}
.edit-modal >>> .dragging-field .form-group.drag-over {
  border-top: 6px solid red;
}
.edit-modal >>> .dragging-field .form-group.drag-over.half-width,
.edit-modal >>> .dragging-field .form-group.drag-over.tier-width,
.edit-modal >>> .dragging-field .form-group.drag-over.two-tiers-width,
.edit-modal >>> .dragging-field .form-group.drag-over.three-quarters-width {
  border-top: 0;
  border-left: 6px solid red;
}

.edit-modal >>> .button {
  padding-bottom: calc(0.375em - 1px);
  padding-left: 0.75em;
  padding-right: 0.75em;
  padding-top: calc(0.375em - 1px);
}
.edit-modal >>> .button.is-small {
  font-size: 0.75em;
}
.edit-modal >>> .button.is-primary {
  background: var(--primary-color);
  color: white;
}
.edit-modal >>> .button.is-danger {
  background: #ff3860;
  color: white;
}
.edit-modal >>> .button.is-success {
  background: #23d160;
  color: white;
}
.edit-modal.mobile.active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10002;
}
.edit-modal.mobile.active >>> .modal-card-foot {
  padding: 0;
}

.edit-modal >>> .form .card-header-title, .edit-modal >>> .form .card-header-icon {
  padding: 5px;
}
.edit-modal >>> .form .card-header-title {
  padding-left: 12px;
  padding-right: 12px;
}
.edit-modal >>> .modal-modes-buttons {
  flex-grow: 1;
  margin-bottom: 0;
}
.edit-modal .add-tab-button >>> a {
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  display: block;
}
</style>
<style src="@/core/vfgfieldstyles.css" />
