<template>
  <div :class="`edit-menu-wrapper ${fixed ? 'fixed': ''}`">
    <div v-if="hasOverlay" class="overlay main-menu-overlay" @click="$emit('close')" />
    <div v-if="oneSubMenuOpened" class="overlay sub-menu-overlay" @click="closeSubMenus" />
    <div :class="`edit-menu main-menu ${fixed ? 'fixed': ''}`">
      <b-menu>
        <b-menu-list>
          <b-menu-item :active.sync="labelInputMenuActive">
            <template slot="label">
              <b-tooltip position="is-right" class="menu-item-tooltip">
                <span slot="content" class="tooltip-text">{{i18n.label_tooltip}}</span>
                <b-input ref="labelInput" v-model="value.label" icon="alphabetical" type="text" />
              </b-tooltip>
            </template>
          </b-menu-item>
          <b-menu-item :active.sync="modelInputMenuActive">
            <template slot="label">
              <b-tooltip position="is-right" class="menu-item-tooltip">
                <span slot="content" class="tooltip-text">{{i18n.key_tooltip}}</span>
                <b-input ref="modelInput" v-model="value.model" icon="key" type="text" />
              </b-tooltip>
            </template>
          </b-menu-item>
          <b-menu-item :icon="fieldIcon(value)" :active.sync="typesMenuActive" class="type-menu-item" >
            <template slot="label">
              {{ i18n.type }}
              ({{ value.type || 'Non défini' }})
              <i class="mdi mdi-menu-right is-pulled-right" />
            </template>
          </b-menu-item>
          <b-menu-item icon="palette" :active.sync="stylesMenuActive">
            <template slot="label">
              {{ i18n.styles_label }}
              ({{ value.styleClasses ? value.styleClasses.length : 0 }})
              <i class="mdi mdi-menu-right is-pulled-right" />
            </template>
          </b-menu-item>
          <li class="menu-item-inactivable">
            <b-tooltip position="is-right" class="menu-item-tooltip">
              <span slot="content" class="tooltip-text">{{i18n.identifier_tooltip}}</span>
              <label class="checkbox">
                <input v-model="value.isIdentifier" type="checkbox" style="float: right; margin-top: 4px;" :disabled="!canBeIdentifier">
                <i class="mdi mdi-key" />
                {{ i18n.identifier }}
              </label>
            </b-tooltip>
          </li>
          <b-menu-item icon="asterisk" :active.sync="validationMenuActive" class="type-menu-item" >
            <template slot="label">
              {{ i18n.validation }}
              <i class="mdi mdi-menu-right is-pulled-right" />
            </template>
          </b-menu-item>
          <li class="menu-item-inactivable">
            <label class="checkbox">
              <input v-model="value.disabled" type="checkbox" style="float: right; margin-top: 4px;">
              <i class="mdi mdi-lock" />
              {{ i18n.disabled }}
            </label>
          </li>
          <b-menu-item icon="settings" :active.sync="advancedOptionsMenuActive">
            <template slot="label">
              {{ i18n.advanced_options }}
              <i class="mdi mdi-menu-right is-pulled-right" />
            </template>
          </b-menu-item>
          <li class="menu-item-inactivable">
            <button type="is-danger" class="button has-tooltip is-pulled-right is-danger" @click="$emit('delete')">
              <span class="custom-tooltip bottom">
                {{ i18n.delete }}
              </span>
              <i class="mdi mdi-delete" />
            </button>
            <div class="field has-addons" style="display: flex">
              <p class="control">
                <button class="button is-success has-tooltip" @click="$emit('save')">
                  <i class="mdi mdi-check" />
                  <span class="custom-tooltip bottom">
                    {{ i18n.save }}
                  </span>
                </button>
              </p>
              <p class="control">
                <button class="button has-tooltip" @click="$emit('close')">
                  <span class="custom-tooltip bottom">
                    {{ i18n.close }}
                  </span>
                  <i class="mdi mdi-close" />
                </button>
              </p>
            </div>
          </li>
        </b-menu-list>
      </b-menu>
    </div>
    <div v-if="stylesMenuActive" class="edit-menu sub-menu styles-menu">
      <b-tabs>
        <b-tab-item v-for="(category, categoryLabel) in stylesByCategory" :key="categoryLabel" :label="categoryLabel">
         <b-menu>
          <b-menu-list>
            <li
              v-for="s in category"
              :key="s.value"
            >
              <a
                :class="`
                ${value.styleClasses && value.styleClasses.indexOf(s.value) !== -1 ? 'item-selected': ''}
                menu-item-inactivable
              `"
                @click="toggleStyle(s)"
              >
                <div style="font-weight: bold">
                  {{ i18n.styles[s.value] || s.value }}
                </div>
                <span class="description">{{ i18n.styles[s.description] || s.description }}</span>
              </a>
            </li>
          </b-menu-list>
        </b-menu>
        </b-tab-item>
      </b-tabs>
    </div>
    <div v-if="typesMenuActive" class="edit-menu sub-menu types-menu">
      <b-menu>
        <b-tabs>
          <b-tab-item v-for="(category, categoryLabel) in fieldsByCategory" :key="categoryLabel">
            <template slot="header">
              <span class="custom-tooltip">
                {{ categoryLabel }}
              </span>
              <i :class="`mdi mdi-${iconForFieldCategory(categoryLabel)}`" />
            </template>
            <b-menu-list class="types-menu-list">
              <b-menu-item v-for="t in category" :key="t.label" :class="`type ${t.deprecated ? 'is-deprecated' : ''}`" @click="setType(t.originalLabel)">
                <template slot="label">
                  <div style="font-weight: bold">
                    <i v-if="t.icon" :class="`mdi mdi-${t.icon}`" />
                    {{ t.label }}
                  </div>
                  <span class="description">{{ t.description }}</span>
                </template>
              </b-menu-item>
            </b-menu-list>
          </b-tab-item>
        </b-tabs>
      </b-menu>
    </div>
    <div v-if="validationMenuActive" class="edit-menu sub-menu validation-menu">
      <b-menu>
        <b-menu-list :label="i18n.validation">
          <vue-form-generator
            class="form property-form"
            :schema="validationSchema"
            :model="value"
            tag="div"
          />
         </b-menu-list>
      </b-menu>
    </div>
    <div v-if="advancedOptionsMenuActive" class="edit-menu sub-menu advanced-options-menu">
      <b-menu>
        <SchemaPropertyEditor :value="value" class="advanced-editor" @input="$emit('input', $event)"/>
      </b-menu>
    </div>
  </div>
</template>
<script>
import vfgFields from '@/core/vfgfields';
import fieldStyles from '@/core/vfgFieldStyles';
import SchemaPropertyEditor from '@/components/SchemaPropertyEditor';
import i18n from 'i18n/components/EditPropertyMenu.json';

export default {
  name: 'EditPropertyMenu',
  components: {
    SchemaPropertyEditor,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    allProperties: {
      type: Array,
      required: true,
    },
    hasOverlay: {
      type: Boolean,
      default: true,
    },
    hasOverlay: {
      type: Boolean,
      default: true,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const fieldsByCategory = {};
    vfgFields.fields.filter(f => f.hidden !== true).map((f) => {
      if (fieldsByCategory[(f.category || 'misc.')] === undefined) {
        fieldsByCategory[(f.category || 'misc.')] = [];
      }
      fieldsByCategory[(f.category || 'misc.')].push(f);
    });

    return {
      i18n,
      fieldsByCategory,
      validationSchema: {
        fields: [{
          type: 'checkbox',
          label: i18n.required,
          model: 'required',
        }, {
          type: 'propertyvalidator',
          label: i18n.validator,
          model: 'validatorFunction',
        }],
      },
      fieldStyles,
      vfgFields,
      stylesMenuActive: false,
      typesMenuActive: false,
      validationMenuActive: false,
      advancedOptionsMenuActive: false,
      modelInputMenuActive: false,
      labelInputMenuActive: false,
    };
  },
  computed: {
    oneSubMenuOpened() {
      return this.stylesMenuActive || this.typesMenuActive || this.validationMenuActive || this.advancedOptionsMenuActive;
    },
    canBeIdentifier() {
      for (const p of this.allProperties) {
        if (p.isIdentifier && p.model !== this.value.model) {
          return false;
        }
      }
      return true;
    },
    stylesByCategory () {
      const res = {};
      for (let s of this.fieldStyles) {
        let cat = s.category;
        if (!cat) {
          cat ='Général';
        }
        if(res[cat] === undefined) {
          res[cat] = [];
        }
        res[cat].push(s);
      }
      return res;
    }
  },
  watch: {
    labelInputMenuActive(v) {
      if (v) {
        this.$refs.labelInput.focus();
      }
    },
    modelInputMenuActive(v) {
      if (v) {
        this.$refs.modelInput.focus();
      }
    },
    value: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },
  methods: {
    setType(type) {
      this.value.type = type;
      this.$emit('input', { ...this.value });
    },
    addNewVisibilityCondition() {
      if (!this.value.visibility) {
        this.value.visibility = [];
      }
      const newValue = {
        ...this.value,
        visibility: [...this.value.visibility, { type: '', params: '' }],
      };
      this.$emit('input', newValue);
    },
    fieldIcon(p) {
      const item = vfgFields.fields.find((f) => f.originalLabel === p.type);
      return item ? item.icon : 'unknown';
    },
    iconForFieldCategory(category) {
      return {
        System: 'cog',
        Lists: 'format-list-bulleted-type',
        Inputs: 'form-textbox',
        Relations: 'link-variant',
        Code: 'code-braces',
      }[category];
    },
    closeSubMenus() {
      this.stylesMenuActive = false;
      this.typesMenuActive = false;
      this.advancedOptionsMenuActive = false;
      this.validationMenuActive = false;
      this.visibilityMenuActive = false;
    },
    toggleStyle(s) {
      if (this.value.styleClasses === undefined) {
        this.value.styleClasses = [];
      }
      if (!this.value.styleClasses.includes(s.value)) {
        this.value.styleClasses.push(s.value);
      } else {
        this.value.styleClasses.splice(this.value.styleClasses.indexOf(s.value), 1);
      }
      this.$emit('input', { ...this.value });
    },
  },
};
</script>
<style scoped>
.edit-menu {
  left: 22px;
  top: 34px;
  width: 200px;
  background: white;
  border-radius: 4px;
  z-index: 10000;
}
.edit-menu.fixed {
  position: fixed;
  left: calc(50% - 840px);
  top: 140px;
}
.edit-menu >>> .menu-label {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 33px;
  white-space: nowrap;
}
.sub-menu {
  position: absolute;
  z-index: 100160;
  left: -6px;
  top: 20px;
  width: 350px;
  max-height: 500px;
  box-shadow: 0 1px 2px 1px rgba(0,1,0,.2);
}
.edit-menu-wrapper.fixed >>> .sub-menu {
  position: fixed;
  left: calc(50% - 636px);
  top: 200px;
}
.sub-menu.styles-menu {
  overflow-y: auto;
}
.validation-menu {
  top: 200px;
  width: 700px;
  height: 300px;
}

.advanced-options-menu {
  top: 200px;
  width: 700px;
}
.visibility-menu {
  top: 438px;
}
.menu-item-inactivable {
  padding: 0.5em 0.75em;
}
.menu-item-inactivable:hover {
  background: whitesmoke;
}
.custom-tooltip {
  position: absolute;
  top: -26px;
  background: var(--primary-color-darker);
  padding: .35rem .75rem;
  color: white;
  border-radius: 4px;
  font-size: 85%;
  box-shadow: 0 1px 2px 1px rgba(0,1,0,.2);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;
}
.custom-tooltip.bottom {
  top: unset;
  bottom: -36px;
  left: -25%;
}
.types-menu >>> .tabs > ul > li:hover .custom-tooltip, .has-tooltip:hover .custom-tooltip {
  opacity: 1;
  display: inline-block;
}
.types-menu >>> .tab-content {
  padding: 0;
  max-height: 459px;
  overflow-y: auto;
}
.overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.sub-menu-overlay {
  z-index: 10002;
}
.item-selected, .item-selected:hover {
  background: var(--primary-color-darker);
  color: white !important;
}
.edit-menu >>> .checkbox {
  width: 100%;
}
.edit-menu >>> .checkbox label {
  padding-left: 0;
}
.edit-menu >>> .checkbox .check {
  position: absolute;
  right: 0;
}
.type.is-deprecated >>> a {
  color: #999;
}
.description {
  font-size: 75%;
}
label {
  text-align: left !important;
  padding: 0 !important;
  font-weight: normal !important;
}
.menu-item-tooltip {
  width: 100%;
}
.edit-menu >>> .tooltip-content {
  margin-left: 20px;
}
.edit-menu >>> .tooltip-text {
  width: 300px;
  white-space: pre-line;
  display: block;
}
.edit-menu >>> ul {
  list-style: none;
  margin: 0;
}
</style>
