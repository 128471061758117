<template>
  <span class="mini-property-editor">
    <EditPropertyMenu
      v-model="valueCopy"
      :all-properties="allProperties"
      @save="$emit('onSave', { value: valueCopy, propertyId })"
      @close="$emit('onClose')"
      @delete="$emit('onDelete', propertyId)"
    />
    <a v-if="hasHandle" class="drag-handle" draggable @dragstart="$emit('dragStart')" @dragend="$emit('dragEnd')">
      <i class="mdi mdi-arrow-all" />
    </a>
  </span>
</template>
<script>
import EditPropertyMenu from '@/components/EditPropertyMenu';

export default {
  name: 'MiniPropertyEditor',
  components: {
    EditPropertyMenu,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    propertyId: {
      type: String,
      default: undefined,
    },
    hasHandle: {
      type: Boolean,
      default: true,
    },
    allProperties: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      valueCopy: { ...this.value },
    };
  },
  watch: {
    value: {
      handler(v) {
        this.valueCopy = { ...v };
        this.$emit('input', v);
      },
      deep: true,
    },
    valueCopy: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.mini-property-editor {
  position: relative;
}
.drag-handle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 22px;
  cursor: move;
  margin-left: -6px;
  z-index: 100006;
  text-align: center;
}
.drag-handle i::before {
  transition: transform 0.3s, color 0.3s, background-color 0.3s;
  border: var(--primary-color) 1px solid;
  background: #FFFFFFAA;
  color: var(--primary-color);
  border-radius: 100%;
  width: 33px;
  height: 33px;
  position: absolute;
  top: calc(50% - 17px);
}
.drag-handle:hover i::before {
  transform: scale(1.3);
  background: var(--primary-color);
  color: white;
}
</style>
