import i18n from 'i18n/components/SchemaPropertyEditor.json';

export default {
  fields: [{
    type: 'textArea',
    label: i18n.help_text,
    model: 'help',
  }, {
    type: 'input',
    inputType: 'text',
    label: i18n.default,
    model: 'default',
  }, {
    type: 'checkbox',
    label: i18n.hide_at_import,
    model: 'hideAtImport',
  }, {
    type: 'function',
    label: 'Getter function',
    model: 'getterFunction',
    params: [{ name: 'model' }],
  }, {
    type: 'function',
    label: 'Setter function',
    model: 'setterFunction',
    params: [{ name: 'model' }],
  }, {
    type: 'function',
    label: 'Visibility function',
    model: 'visibilityFunction',
    params: [{ name: 'model' }],
  }],
};
