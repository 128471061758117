export default {
  fields: [{
    type: 'iconSelector',
    model: 'icon',
    label:'',
    styleClasses: [
      'quarter-width',
    ],
    get: (model) => model.icon || 'file'
  }, {
    type: 'text',
    model: 'name',
    label: 'Nom de l\'onglet',
    styleClasses: [
      'half-width',
    ],
  }, {
    type: 'select',
    model: 'type',
    values: ['template', 'list', 'comments'],
    label: 'type d\'onglet',
    styleClasses: [
      'quarter-width',
    ],
  }, {
    type: 'code',
    model: 'template',
    label: 'template',
  }, {
    type: 'text',
    model: 'collection',
    label: 'Collection',
    visible: (model) => model.type === 'list',
  }, {
    type: 'text',
    model: 'query',
    label: 'Requete',
    visible: (model) => model.type === 'list',
  },{
    label: 'Schema',
    model: 'schema',
    type: 'document',
    labelField: 'name',
    relation: {
      type: 'hasOne',
      collection: 'schemas',
    },
    onlyFieldAsString: 'name',
    hint: "Nom du schéma, utilisé pour gérer le formulaire d'édition des enregistrements",
  }]
};
